import React, { useState } from "react";
import Navigation from "../components/Navigation";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import CookieConsent from "react-cookie-consent";

import HeaderImg from "../images/main_banner.jpg";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: ""
  });

  const updateField = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    console.log(JSON.stringify(formData));

    fetch("http://slspanama.com/mail/mail.php", {
      method: "post",
      // body: JSON.stringify(formData)
      body: formData
    })
      .then(res => res.json())
      .then(res => setFormData({ ...formData, result: JSON.stringify(res) }));
  };
  return (
    <Layout>
      <SEO title="SLS Panamá - CONTACT" />

      <div
        className="top_header pb-20"
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundColor: `#ddd`,
          backgroundSize: `cover`
        }}
      >
        <Navigation />

        <div className="w-full text-center">
          <h1 className="minerva text-3xl lg:text-6xl text-white ml-5 lg:ml-0 mx-auto ">
            CONTACT US
          </h1>
        </div>
      </div>

      <div className="w-10/12 lg:w-8/12 bg-white mx-auto mb-20">
        <h2 className="minerva is_uppercase text-6xl text-black mt-10">
          Thank You
        </h2>
        <div className="lg:flex">
          <div className="contacttext w-full lg:w-1/2">
            <p className="minerva text-black text-lg mt-10 normal-case">
              Corporate Office Hours:
            </p>
            <p className="minerva text-black text-lg mt-5 normal-case">
              SLSPSP, PDC, 13th Floor, Obarrio, 56 S, Panama City, PA
            </p>
            <p className="minerva text-black text-lg mt-5 normal-case">
              Office Remains Partially Operational during the COVID19 Emergency.
            </p>
            <p className="minerva text-black text-lg mt-5 normal-case">
              Please contact before visit to comply with all Health Protocols.
            </p>
            <p className="minerva text-black text-lg mt-5 normal-case">
              Let’s get the conversation going.
            </p>{" "}
            <p className="minerva text-black text-lg mt-5 normal-case">
              Phone: +507 831 7592
            </p>
            <p className="minerva text-black text-lg mt-5 normal-case">
              Email:{" "}
              <a href="mailto:corporate@slsmain.com" className="underline">
                corporate@slsmain.com
              </a>
            </p>
          </div>
          <div className="contactform w-full lg:w-1/2 mt-10 lg:px-5">
            <form action="http://slspanama.com/mail/mail.php" method="post">
              <label
                htmlFor="fname"
                className="minerva uppercase block text-lg"
              >
                First Name:
                <input
                  value={formData.fname}
                  onChange={updateField}
                  type="text"
                  name="fname"
                  id="fname"
                  required="required"
                  placeholder="First Name"
                  className="block border border-gray-600 px-3 py-2 outline-none mt-2"
                />
              </label>

              <label
                htmlFor="lname"
                className="minerva uppercase block text-lg mt-5"
              >
                Last Name
                <input
                  value={formData.lname}
                  onChange={updateField}
                  type="text"
                  name="lname"
                  required="required"
                  placeholder="Last Name"
                  className="block border border-gray-600 px-3 py-2 outline-none mt-2"
                />
              </label>

              <label
                htmlFor="email"
                className="minerva uppercase block text-lg  mt-5"
              >
                Email
                <input
                  value={formData.email}
                  onChange={updateField}
                  type="text"
                  name="email"
                  required="required"
                  placeholder="Your Email"
                  className="block border border-gray-600 px-3 py-2 outline-none mt-2"
                />
              </label>

              <label
                htmlFor="phone"
                className="minerva uppercase block text-lg mt-5"
              >
                Phone Number
                <input
                  value={formData.phone}
                  onChange={updateField}
                  type="text"
                  name="phone"
                  required="required"
                  placeholder="Phone Number"
                  className="block border border-gray-600 px-3 py-2 outline-none mt-2"
                />
              </label>

              <label
                htmlFor="message"
                className="minerva uppercase block text-lg mt-5"
              >
                Your Message
                <textarea
                  value={formData.message}
                  onChange={updateField}
                  name="message"
                  required="required"
                  className="block border border-gray-600 px-3 py-2 outline-none mt-2"
                ></textarea>
              </label>

              <button
                type="submit"
                className="minerva uppercase px-3 py-2 bg-gray-700 text-white mt-4 rounded-sm"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="ACCEPT"
        cookieName="conscentcookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}>No personal data is stored.</span>
      </CookieConsent>
    </Layout>
  );
};

export default ContactPage;
